/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import HomeRoutes from "./HomeRoutes"


export function AuthPage() {

  return (
    <>
      <HomeRoutes />
    </>
  );
}
