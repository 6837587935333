if(process.env.REACT_APP_ENV === 'qc'){

    var apilink = {
        LOGIN : process.env.REACT_APP_LOGIN ,
        FETCH_DIAMOND : process.env.REACT_APP_FETCH_DIAMOND,
        IUD_SHORTLIST : process.env.REACT_APP_IUD_SHORTLIST,
        SEARCH_COUNT : process.env.REACT_APP_SEARCH_COUNT,
        SEARCH_LOG : process.env.REACT_APP_SEARCH_LOG,
        IUD_CONSUMER_DETAILS : process.env.REACT_APP_IUD_CONSUMER_DETAILS,
        FETCH_COUNSUMER_LIST : process.env.REACT_APP_FETCH_COUNSUMER_LIST,
        FETCH_CONSUMER_SHORTLIST_DATA : process.env.REACT_APP_FETCH_CONSUMER_SHORTLIST_DATA,
        FETCH_CURRENCY : process.env.REACT_APP_FETCH_CURRENCY,
        DETAILPAGE_LOG : process.env.REACT_APP_IUD_DETAILPAGE_LOG,
        BASE_URL: "http://localhost:3000/logout",
        NODE_API_URL:process.env.REACT_APP_BETANODE_URL
    };

}
else if(process.env.REACT_APP_ENV === 'production'){

    var apilink = {
        LOGIN : process.env.REACT_APP_LOGIN_PROD ,
        FETCH_DIAMOND : process.env.REACT_APP_FETCH_DIAMOND_PROD,
        IUD_SHORTLIST : process.env.REACT_APP_IUD_SHORTLIST_PROD,
        SEARCH_COUNT : process.env.REACT_APP_SEARCH_COUNT_PROD,
        SEARCH_LOG : process.env.REACT_APP_SEARCH_LOG_PROD,
        IUD_CONSUMER_DETAILS : process.env.REACT_APP_IUD_CONSUMER_DETAILS_PROD,
        FETCH_COUNSUMER_LIST : process.env.REACT_APP_FETCH_COUNSUMER_LIST_PROD,
        FETCH_CONSUMER_SHORTLIST_DATA : process.env.REACT_APP_FETCH_CONSUMER_SHORTLIST_DATA_PROD,
        FETCH_CURRENCY : process.env.REACT_APP_FETCH_CURRENCY_PROD,
        DETAILPAGE_LOG : process.env.REACT_APP_IUD_DETAILPAGE_LOG_PROD,
        BASE_URL: "http://localhost:3000/logout",
        NODE_API_URL:process.env.REACT_APP_PRODNODE_URL
    };

}else{

    var apilink = {
        LOGIN : process.env.REACT_APP_LOGIN_PROD ,
        FETCH_DIAMOND : process.env.REACT_APP_FETCH_DIAMOND_PROD,
        IUD_SHORTLIST : process.env.REACT_APP_IUD_SHORTLIST_PROD,
        SEARCH_COUNT : process.env.REACT_APP_SEARCH_COUNT_PROD,
        SEARCH_LOG : process.env.REACT_APP_SEARCH_LOG_PROD,
        IUD_CONSUMER_DETAILS : process.env.REACT_APP_IUD_CONSUMER_DETAILS_PROD,
        FETCH_COUNSUMER_LIST : process.env.REACT_APP_FETCH_COUNSUMER_LIST_PROD,
        FETCH_CONSUMER_SHORTLIST_DATA : process.env.REACT_APP_FETCH_CONSUMER_SHORTLIST_DATA_PROD,
        FETCH_CURRENCY : process.env.REACT_APP_FETCH_CURRENCY_PROD,
        DETAILPAGE_LOG : process.env.REACT_APP_IUD_DETAILPAGE_LOG_PROD,
        BASE_URL: "http://localhost:3000/logout",
        NODE_API_URL:process.env.REACT_APP_LOCALNODE_URL
    };
}


//mysql connection database for beta server

//exporting apilink

module.exports = apilink;