import React from "react";
import {  Switch,Redirect, Route } from 'react-router-dom';
import Login from "./Login";


const HomeRoutes = () =>{
  return(
    <>
      <Switch>
        <Route exact path="/" component={Login} />
        <Redirect from="/auth" exact={true} to="/" />
        <Redirect to="/" />
      </Switch>
    </>
  )
}
export default HomeRoutes;