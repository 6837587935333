import React, { useState,useRef, useEffect } from 'react'
import IdleTimer from 'react-idle-timer'
import { useHistory } from "react-router-dom";
// import Modal from 'react-modal';
import {
    Modal,
    Button,
} from "react-bootstrap";

function IdleTimerContainer() {
    const history = useHistory();
    const id = React.useMemo(()=>localStorage.getItem("C_id"),[])
    const [show, setShow] = React.useState(false);
    const [countdown, setCounterdown] = useState();
    const idelTimerRef = useRef(null);

    function handleClose(event, reason) {
        setShow(false)
    }

    const onIdle = () => {
        localStorage.clear();
        window.location.reload(true)
        
    }

    var timer = useRef();
    const sessionOut = () => {
       if( id === "-1" || id === "-2" || id === "") {
            setShow(false)
        } else {
            setShow(true)
            setCounterdown(30)
            timer.current = setInterval(() => setCounterdown(prev => prev - 1), 1000);
            return () => clearInterval(timer.current);
        }
    }   

    const sessionExpire = () => {
        window.localStorage.setItem("C_id", -1)
            setShow(false)
            setTimeout(() => {
                setTimeout(() => {
                    window.location.reload(true)
                }, 100);
                history.push({  pathname: '/searchdiamond'})
            }, 500);
     }  

    useEffect(() => {
        if(countdown <= 0 && (id !== "-1" || id !== "-2" || id !== "" ) && show === true){
            clearInterval(timer.current);
            sessionExpire()
        }
    },[countdown]);

    return (
        <div>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                size="md"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Warning !</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <div className="row">
                       <div className='col-12 mb-5'> <h6>You've been idle for a while</h6></div >
                        <div className='col-12'>
                            <button className="btn btn-warning mr-4 btn-square" onClick={sessionExpire}>End Sesssion ( 00 : {countdown} )</button>     
                            <button className="btn btn-success btn-square" onClick={handleClose}>I'm here</button>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <IdleTimer
                ref={idelTimerRef}
                // timeout={12 * 100000} // 20 minuts
                timeout={36 * 100000} // 60 minuts
                onIdle={onIdle}>
            </IdleTimer>
            <IdleTimer
                ref={idelTimerRef}
                timeout={9 * 100000} // 15 minuts
                // timeout={6 * 100000} // 10 minuts
                onIdle={sessionOut}>
            </IdleTimer>
        </div>
    )
}
export default IdleTimerContainer;