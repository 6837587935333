import React, { Suspense } from "react";
import { Redirect, Switch, } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";

import "../assets/css/BackendStyle.css"
import { Search,ProductDetailMain  } from "../_metronic/_partials";
import IdleTimerContainer from "./IdleTimerContainer"

export default function BasePage() {

  return (
    <>
        <IdleTimerContainer /> 
        <Suspense fallback={<LayoutSplashScreen />}>
          <Switch>
            <Redirect exact from="/" to="/naturalsearch" />  
            <ContentRoute path="/naturalsearch" component={Search} />
            <ContentRoute path="/labgrownsearch" component={Search} />
            <ContentRoute path="/details" component={ProductDetailMain} />
            <Redirect to="error/error-v1" />
          </Switch>
        </Suspense>
    </>

  );
}
