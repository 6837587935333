import React from "react";

export default function Sorting(props) {
    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    return (
        <div className="select_block select_block_border">
            <span className="w-100 mr-2">Sort by: {props.sorting ? capitalizeFirstLetter(props.sorting.split('_')[0]) : ""}</span>
            <select
                className="custom-select"
                onChange={(e) => props.setSorting(e.target.value)}
                value={props.sorting || ""}
            >
                <option value="" disabled selected>
                    Select
                </option>
                <optgroup label="Carat">
                    <option selected={props.sorting === "carat_desc"} value="carat_desc">
                        high to low
                    </option>
                    <option selected={props.sorting === "carat_asc"} value="carat_asc">
                        low to high
                    </option>
                </optgroup>
                <optgroup label="Color">
                    <option selected={props.sorting === "color_desc"} value="color_desc">
                        high to low
                    </option>
                    <option selected={props.sorting === "color_asc"} value="color_asc">
                        low to High
                    </option>
                </optgroup>
                <optgroup label="Clarity">
                    <option selected={props.sorting === "clarity_desc"} value="clarity_desc">
                        high to low
                    </option>
                    <option selected={props.sorting === "clarity_asc"} value="clarity_asc">
                        low to high
                    </option>
                </optgroup>
                {/* <optgroup label="Price">
                    <option selected={props.sorting === "price_desc"} value="price_desc">
                        high to low
                    </option>
                    <option selected={props.sorting === "price_asc"} value="price_asc">
                        low to high
                    </option>
                </optgroup> */}
            </select>
        </div>
    );
}
