import React, { useMemo } from "react";
import objectPath from "object-path";
import { Link } from "react-router-dom";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { QuickUserToggler } from "../extras/QuiclUserToggler";
import { FaPowerOff } from 'react-icons/fa';
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export function Topbar() {
  const uiService = useHtmlClassService();
  
  const layoutProps = useMemo(() => {
    return {
      viewUserDisplay: objectPath.get(uiService.config, "extras.user.display"),
    };
  }, [uiService]);

  return (
    <div className="topbar align-items-center">
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="quick-user-tooltip">Logout</Tooltip>}
        >
          <Link to="/logout" >
            <FaPowerOff className="font-size-h3 text-primary text-hover-warning cursor-pointer" />
          </Link> 
        </OverlayTrigger>
      {layoutProps.viewUserDisplay && <QuickUserToggler />}
    </div>
  );
}
