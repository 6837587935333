import React, { useState, useEffect } from "react";
import { Modal,Button } from "react-bootstrap";
import { Formik, Field } from "formik";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import "react-bootstrap-typeahead/css/Typeahead.css";
import axios from "axios";
import {IUD_CONSUMER_DETAILS} from "./../../../env_config"
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
const NewSesModelSchema = Yup.object().shape({
    ListName: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Required"),
});

export const NewSesModel = (props) => {
    const history = useHistory();
    const [showNewModel, setshowNewModel] = useState(false);
    const [loading, setLoading] = useState(false);
    const [user, setUser] = React.useState([]);
    React.useEffect(() => {
        const userData = localStorage.getItem("userData");
        setUser(JSON.parse(userData))
    },[])

    const [id, setId] = useState([]);

    useEffect(() => window.localStorage.setItem("C_id", id), [id]);

    // const setValue = () => setId(values);

    const handleClose = () => setshowNewModel(false);
    const handleShow = () => {
        console.log( props.setSessModalShow)
        setshowNewModel(true);
        // props.setSessModalShow(false)
    }
    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };
 if(user === undefined ){
        return
    }
    return (
        <>
            <button className="btn btn-success btn-lg" onClick={handleShow}>
                Start with New Session
            </button>
            <Formik
                enableReinitialize
                initialValues={{
                    Tag: "I",
                    ConsumerId: 0,
                    CustomerId: `${user.CustomerId}`,
                }}
                validationSchema={NewSesModelSchema}
                onSubmit={(values, { setSubmitting }) => {
                    enableLoading();
                    setTimeout(() => {
                        console.log(JSON.stringify(values, null, 2));
                        axios({
                            method: "POST",
                            url: IUD_CONSUMER_DETAILS,
                            headers: {
                                "Access-Control-Allow-Origin": "*",
                                "Content-Type": "application/json",
                            },
                            data: JSON.stringify(values, null, 2),
                        })
                            .then(function(res) {
                                disableLoading();
                                console.log(res.data.Message,"res.data.Message")
                                if(res.data.Message.includes("Insert of Consumer details failed.") || res.data.Message.includes("Insert/ Update Into WhiteLabelConsumerDetails Failed.")){
                                    alert("List Name Already Exist");
                                    handleClose()
                                    props.setOpen(false)
                                    props.setSessModalShow(false)
                                }else{
                                    alert("Thank You for taking the time to fill out the form");
                                    setId(res.data.LastInsertedConsumerId);
                                    if (res.data.status){
                                        history.push("/searchdiamond");
                                    }
                                    setSubmitting(false)
                                    setTimeout(() => {
                                        window.location.reload(true)
                                    }, 100);
                                    handleClose()
                                }
                            })
                            .catch(function(res) {
                                setSubmitting(false)
                                alert(res.data.Message);
                                disableLoading();
                            });
                    }, 1000);
                }}
            >
                {({ handleSubmit, errors, touched, isSubmitting ,setFieldValue}) => (
                    <>
                        <Modal
                            show={showNewModel}
                            onHide={handleClose}
                            backdrop="static"
                            keyboard={false}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Add Consumer</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="row">
                                    <div className="col-12">
                                        <label className="font-weight-bold">
                                            List Name :
                                        </label>
                                        <div className="form-group fv-plugins-icon-container">
                                            <Field
                                                className="form-control"
                                                type="text"
                                                name="ListName"
                                                id="ListName"
                                                placeholder="List Name"
                                                autoComplete="off"
                                            />
                                            {errors.ListName &&
                                            touched.ListName ? (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">
                                                        {errors.ListName}
                                                    </div>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <label className="font-weight-bold">
                                            Consumer Name :
                                        </label>
                                        <div className="form-group">
                                            <Field
                                                className="form-control"
                                                type="text"
                                                name="ConsumerName"
                                                id="ConsumerName"
                                                placeholder="Consumer Name"
                                                autoComplete="off"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <label className="font-weight-bold">
                                            Email Address :
                                        </label>
                                        <div className="form-group">
                                            <Field
                                                className="form-control"
                                                type="email"
                                                name="EMail"
                                                id="EMail"
                                                placeholder="email"
                                                autoComplete="off"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <label className="font-weight-bold">
                                            Contact No. :
                                        </label>
                                        <div className="form-group">
                                            <PhoneInput
                                                // className="form-control"
                                                type="text"
                                                name="ContactNo"
                                                id="ContactNo"
                                                placeholder="contact number"
                                                autoComplete="off"
                                                onChange={(value) => setFieldValue("ContactNo",value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <label className="font-weight-bold">
                                            Notes :
                                        </label>
                                        <div className="form-group">
                                            <Field
                                                component="textarea"
                                                className="form-control"
                                                type="text"
                                                name="Notes"
                                                id="Notes"
                                                placeholder="Add notes"
                                                autoComplete="off"
                                                maxLength="250"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                    disabled={isSubmitting}
                                    onClick={handleSubmit}
                                >
                                    start
                                    {loading && (
                                        <span className="ml-3 spinner spinner-white"></span>
                                    )}
                                </button>
                            </Modal.Footer>
                        </Modal>
                    </>
                )}
            </Formik>
        </>
    );
};
