import React, { useEffect , useState } from "react";
import { Slider, TextField,makeStyles } from "@material-ui/core";
// import { Field } from "formik";
// import {toAbsoluteUrl} from "../../../../_helpers";
// import SVG from "react-inlinesvg";
// import {OverlayTrigger,Tooltip} from "react-bootstrap";

const useStyles = makeStyles({
    TextField: {
      width: 80,
      margin:0
    },
  });

export const Carat = ({formik}) => {
    const classes = useStyles();
    const [minCarat, setMinCarat] = useState(0);
    const [maxCarat, setMaxCarat] = useState(30);
    const [caratRangeValue, setCaratRangeValue] = useState([0, 30]);
    const minCaratRange = 0;
    const maxCaratRange = 30;

    useEffect(() => {
        formik.setFieldValue("minCarat", minCarat);
        formik.setFieldValue("maxCarat", maxCarat);
    }, []);

    const handleCaratRangeChange = (event, newValue) => {
        setMinCarat(newValue[0]);
        setMaxCarat(newValue[1]);
        setCaratRangeValue(newValue);
        // formik.setFieldValue("minCarat", newValue[0]);
        // formik.setFieldValue("maxCarat", newValue[1]);
    };

    const handleCaratRangeChangeCommited = (event, newValue) => {
        formik.setFieldValue("minCarat", newValue[0]);
        formik.setFieldValue("maxCarat", newValue[1]);
    };

    return (
        <>
            <div className="row">
                <div className="col-12 font-size-lg font-weight-bold mb-3">Carat</div>
                <div className="col-12">
                    <div className="row m-0">
                        <div className="col-md-12">
                            <Slider
                                getAriaLabel={() => "Carat Range"}
                                value={caratRangeValue}
                                onChangeCommitted={handleCaratRangeChangeCommited}
                                onChange={handleCaratRangeChange}
                                step={0.01}
                                valueLabelDisplay="auto"
                                // getAriaValueText={caratRangeValue}
                                min={minCaratRange}
                                max={maxCaratRange}
                                color="secondary"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 d-flex justify-content-between">
                            <TextField
                                className={classes.TextField}
                                margin="dense"
                                name="minCarat"
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                value={caratRangeValue[0]}
                                color="secondary"
                                inputProps={{
                                    style: {
                                      padding: 5,
                                      textAlign:"center"
                                    }
                                 }}
                                onChange={(e) => {
                                    setMinCarat(Number(e.target.value));
                                    setCaratRangeValue([
                                        Number(e.target.value),
                                        caratRangeValue[1],
                                    ]);
                                    formik.setFieldValue("minCarat", Number(e.target.value));
                                }}
                            />
                                
                            <TextField
                                className={classes.TextField}
                                margin="dense"
                                variant="outlined"
                                name="maxCarat"
                                InputLabelProps={{ shrink: true }}
                                value={ caratRangeValue[1]}
                                color="secondary"
                                inputProps={{
                                    style: {
                                      padding: 5,
                                      textAlign:"center"
                                    }
                                 }}
                                onChange={(e) => {
                                    setMaxCarat(Number(e.target.value));
                                    setCaratRangeValue([
                                        caratRangeValue[0],
                                        Number(e.target.value),
                                    ]);
                                    formik.setFieldValue("maxCarat", Number(e.target.value));
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
