import axios from "axios";
import {LOGIN} from "./../../../../env_config"

export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";


export const ME_URL = LOGIN;

// export function login(email, password) {
//    const values = JSON.stringify({
//     "EMail": email,
//     "Password": password
//   });
  
//   axios({
//     method: 'GET',
//     url: "http://localhost:8080/WLSPAuthentication",
//     headers: { 
//         'Access-Control-Allow-Origin': '*', 
//         'Content-Type': 'application/json'
//       },
//     data: values
//   })
//     .then(function (res) {
//       console.log(values)
//        console.log(res)

//       //  alert('Data added successfully');  
//     })
//     .catch(function (res) {
//       console.warn(values)
//        console.warn(res)
//   });

//   return axios.post(LOGIN_URL, { email, password });
//   // const values = JSON.stringify({
//   //   "EMail": email,
//   //   "Password": password
//   // });
//   // axios({
//   //   method: 'GET',
//   //   url: LOGIN_URL,
//   //   headers: { 
//   //       'Access-Control-Allow-Origin': '*', 
//   //       'Content-Type': 'application/json'
//   //     },
//   //   data: values
//   // })
//   //   .then(function (res) {
//   //     console.log(values)
//   //      console.log(res)

//   //     //  alert('Data added successfully');  
//   //   })
//   //   .catch(function (res) {
//   //     console.warn(values)
//   //      console.warn(res)
//   // });
// }

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}
