import React, {useMemo} from "react";
import {Link} from "react-router-dom";
import { useSelector } from "react-redux";
import objectPath from "object-path";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../_helpers";
import {useHtmlClassService} from "../../_core/MetronicLayout";

export function HeaderMobile() {
  const uiService = useHtmlClassService();

  // const { user } = useSelector((state) => state.auth);
  const [user, setUser] = React.useState([]);
  const [imageBroken, setImageBroken] = React.useState(false);

  React.useEffect(() => {
    const userData = localStorage.getItem("userData");
    setUser(JSON.parse(userData))
  },[])

  const layoutProps = useMemo(() => {
    return {
      headerLogo: uiService.getStickyLogo(),
      asideDisplay: objectPath.get(uiService.config, "aside.self.display"),
      headerMenuSelfDisplay:
          objectPath.get(uiService.config, "header.menu.self.display") === true,
      headerMobileCssClasses: uiService.getClasses("header_mobile", true),
      headerMobileAttributes: uiService.getAttributes("header_mobile")
    };
  }, [uiService]);
  
  if(user === undefined ){
      return
  }
  return (
      <>
        {/*begin::Header Mobile*/}
        <div
            id="kt_header_mobile"
            className={`header-mobile align-items-center shadow-sm ${layoutProps.headerMobileCssClasses}`}
            {...layoutProps.headerMobileAttributes}
        >
          {/*begin::Logo*/} 
          {user.Logo ?
            imageBroken ? (
                <h2 className="text-uppercase text-dark"> {user.CompanyName} </h2>
              ) : (
                <img
                alt={user.CompanyName}
                src={`https://alpha.diamondsupload.com/assets/white_label/${user.Logo}`}
                  height="50px"
                  onError={() => setImageBroken(true)}
                />)
            :<h2 className="text-uppercase text-dark"> {user.CompanyName} </h2>}
          {/*end::Logo*/}

          {/*begin::Toolbar*/}
          <div className="d-flex align-items-center">
            {layoutProps.asideDisplay && (
                <>
                  {/*begin::Aside Mobile Toggle*/}
                  <button className="btn p-0 burger-icon burger-icon-left" id="kt_aside_mobile_toggle">
                    <span/>
                  </button>
                  {/*end::Aside Mobile Toggle*/}
                </>
            )}

            {/*begin::Topbar Mobile Toggle*/}
            <button
                className="btn btn-hover-text-primary p-0 ml-2"
                id="kt_header_mobile_topbar_toggle"
            >
              <span className="svg-icon svg-icon-xl">
                <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
              </span>
            </button>
            {/*end::Topbar Mobile Toggle*/}
          </div>
          {/*end::Toolbar*/}
        </div>
        {/*end::Header Mobile*/}
      </>
  );
}
